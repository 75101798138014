import Head from 'next/head'
import { useEffect } from 'react'
import type { GetServerSideProps } from 'next'
import { withRequiredOrOptionalSession } from '@web-app/hoc/withSession'
import { withOnboarding } from '@web-app/hoc/withOnboarding'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { LandingLayout } from '@web-app/components/layout/LandingLayout'
import { HeroEarn } from '@web-app/components/hero/HeroEarn'
import { BannerTextUnderline } from '@web-app/components/product/BannerTextUnderline'
import { ATMInstantCashCopy } from '@web-app/components/product/ATMInstantCashCopy'
import { TestimonialsGrid } from '@web-app/components/product/TestimonialsGrid'
import { BottomCallToAction } from '@web-app/components/product/BottomCallToAction'

const IndexPage = () => {
  const { trackEvent } = useEventTracking()

  useEffect(() => {
    trackEvent('view_index', { comp: 'IndexPage' })
  }, [])

  return (
    <>
      <Head>
        <title>
          ATM Instant Cash™ - Cash straight to your bank. No credit check
          required.
        </title>
        <meta
          name="description"
          content="Get up to $50*, no interest, no credit check. *Eligibility is based on review and approval. Not all applicants
          will be eligible for ATM Instant Cash™."
        />
      </Head>
      <LandingLayout
        hero={
          <HeroEarn
            title={
              <>
                Get up to $50* with ATM{' '}
                <span className="whitespace-pre">Instant Cash™</span>
              </>
            }
            description={
              <>
                Cash straight to your bank.{' '}
                <span className="whitespace-pre">
                  No credit check required.
                </span>
              </>
            }
            cta="Get Started"
            handleClickEvent={() =>
              trackEvent('click_hero_get_started', { comp: 'HeroEarn' })
            }
          />
        }
      >
        <BannerTextUnderline />
        <ATMInstantCashCopy amount={50} />
        <TestimonialsGrid />
        <BottomCallToAction
          // description="Get Cash Advance"
          // hideDescription={true}
          // customDescription={
          //   <ul className="my-3 space-y-2">
          //     {['No Credit Check', 'No Interest', 'No Late Fees'].map(
          //       (item, index) => (
          //         <li
          //           key={index}
          //           className="flex items-center text-xl font-medium"
          //         >
          //           <span className="mr-3 inline-block h-6 w-6 bg-[url('/svg/circle-check.svg')] bg-contain bg-center bg-no-repeat"></span>
          //           {item}
          //         </li>
          //       )
          //     )}
          //   </ul>
          // }
          handleClickEvent={() =>
            trackEvent('click_bottom_get_started', {
              comp: 'BottomCallToAction',
            })
          }
        />
      </LandingLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps =
  withRequiredOrOptionalSession(
    { required: false },
    withOnboarding(async (_context) => {
      return { props: {} }
    })
  )

export default IndexPage
